import React, { useEffect, useState } from 'react';
import {
  Input,
  FormControl,
  FormLabel,
  Textarea,
  Flex,
  Select,
  Image,
  FormErrorMessage,
} from '@chakra-ui/react';
import { handleImageUpload } from 'utils/handleImageUpload';
// import { handleProductImageUpload } from 'utils/handleImageUpload';

const EditProductDetails = ({ formData, setFormData }) => {
  const [durationValue, setDurationValue] = useState('');
  const [durationUnit, setDurationUnit] = useState('min.');
  const [imagePreview, setImagePreview] = useState('');
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (formData.duration) {
      const [value, unit] = formData.duration.split(' ');
      setDurationValue(value || '');
      setDurationUnit(unit || 'min.');
    }

    if (formData.image && typeof formData.image === 'string') {
      setImagePreview(formData.image);
    }
  }, [formData, setFormData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDurationChange = (e) => {
    const { name, value } = e.target;
    if (name === 'durationValue') {
      setDurationValue(value);
      setFormData((prevData) => ({
        ...prevData,
        duration: `${value} ${durationUnit}`.trim(),
      }));
    } else if (name === 'durationUnit') {
      setDurationUnit(value);
      setFormData((prevData) => ({
        ...prevData,
        duration: `${durationValue} ${value}`.trim(),
      }));
    }
  };

  return (
    <>
      <FormControl mb={3}>
        <FormLabel>Name</FormLabel>
        <Input
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="Enter product name"
        />
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Price</FormLabel>
        <Input
          name="price"
          type="number"
          value={formData.price}
          onChange={handleInputChange}
          placeholder="Enter product price"
        />
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Discount Percentage</FormLabel>
        <Input
          name="discountPercentage"
          type="number"
          value={formData.discountPercentage}
          onChange={handleInputChange}
          placeholder="Enter discount percentage"
        />
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Duration</FormLabel>
        <Flex>
          <Input
            name="durationValue"
            type="number"
            value={durationValue}
            onChange={handleDurationChange}
            placeholder="Enter duration"
            mr={2}
          />
          <Select
            name="durationUnit"
            value={durationUnit}
            onChange={handleDurationChange}
            width="100px"
          >
            <option value="min.">min.</option>
            <option value="hr.">hr.</option>
          </Select>
        </Flex>
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Details</FormLabel>
        <Textarea
          name="details"
          value={formData.details}
          onChange={handleInputChange}
          placeholder="Enter product details"
        />
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Upload Image</FormLabel>
        <Input
          name="image"
          type="file"
          accept="image/*"
          onChange={(e) =>
            handleImageUpload(e, setErrors, setFormData, setImagePreview)
          }
        />
        <FormErrorMessage>{errors.image}</FormErrorMessage>

        {imagePreview && (
          <Image src={imagePreview} alt="Product Image" mt={2} />
        )}
      </FormControl>
    </>
  );
};

export default EditProductDetails;
