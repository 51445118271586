// Chakra imports
import {
  Box,
  Flex,
  Image,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
import React from 'react';

export default function TotalSpent(props) {
  const { ...rest } = props;

  // Chakra Color Mode

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = useColorModeValue('secondaryBlack.600', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  return (
    <Card
      justifyContent="center"
      align="center"
      direction="column"
      w="100%"
      mb="0px"
      gap="2rem"
      {...rest}
    >
      <Flex justify="space-between" ps="0px" pe="20px" pt="5px">
        <Flex align="center" w="100%">
          <Text
            bg={boxBg}
            fontSize="sm"
            fontWeight="500"
            padding={2}
            color={textColorSecondary}
            borderRadius="7px"
          >
            Top Partner
          </Text>
          {/* <Button
            ms="auto"
            align="center"
            justifyContent="center"
            bg={bgButton}
            _hover={bgHover}
            _focus={bgFocus}
            _active={bgFocus}
            w="37px"
            h="37px"
            lineHeight="100%"
            borderRadius="10px"
            {...rest}
          >
            <Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
          </Button> */}
        </Flex>
      </Flex>
      <Flex w="100%" flexDirection={{ base: 'column', lg: 'row' }}>
        <Box me="20px">
          <Image
            src="https://via.placeholder.com/150"
            alt="Customer Image"
            borderRadius="full"
            boxSize="100px"
          />
        </Box>
        <Flex flexDirection="column" justify="center" textAlign="start">
          <Text color={textColor} fontSize="24px" fontWeight="700" mb="4px">
            John Doe
          </Text>
          <Text color="secondaryGray.600" fontSize="md" mb="2px">
            Phone: +1 234 567 890
          </Text>
          <Text color="secondaryGray.600" fontSize="md" mb="2px">
            Booking Count: 15
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}
